export default [
  {
    title: "g.personnel",
    icon: "UsersIcon",
    permission: "users",
    children: [
      {
        title: "g.UsersList",
        route: "UsersList",
        subPermission: "index",
      },
      {
        title: "g.AddUser",
        route: "AddUser",
        subPermission: "store",
      },
    ],
  },

  {
    title: "g.equipment",
    icon: "CpuIcon",
    permission: "devices",
    children: [
      {
        title: "g.EquipmentList",
        route: "EquipmentList",
        subPermission: "index",
      },
      {
        title: "g.AddEquipment",
        route: "AddEquipment",
        subPermission: "store",
      },
      {
        title: "g.workInstruction",
        route: "WorkInstructionList",
        subPermission: "index",
      },

      {
        title: "g.equipmentUnderMaintenance",
        route: "EquipmentUnderMaintenance",
        subPermission: "index",
      },
      {
        title: "g.EquipmentInTheLabList",
        route: "EquipmentInTheLabList",
        subPermission: "index",
      },
      {
        title: "g.ProcedureOfExpandCalibrationCertificatePeriodList",
        route: "ProcedureOfExpandCalibrationCertificatePeriodList",
        subPermission: "index",
      },
      // {
      //   title: "g.equipmentCalibrationPlan",
      //   route: "EquipmentCalibrationPlan",
      // },
      // {
      //   title: "g.addEquipmentCalibrationPlan",
      //   route: "AddEquipmentCalibrationPlan",
      // },
      {
        title: "g.equipmentNominatedForMaintenance",
        route: "EquipmentNominatedForMaintenance",
        subPermission: "index",
      },
      {
        title: "g.listOfElectricTypes",
        route: "ElectricTypesList",
        subPermission: "index",
      },
      {
        title: "g.addElectricType",
        route: "AddElectricType",
        subPermission: "store",
      },
    ],
  },

  {
    title: "g.maintenance",
    icon: "ToolIcon",
    children: [
      {
        title: "g.maintenanceTypesList",
        route: "maintenanceTypesList",
      },
      {
        title: "g.addMaintenanceType",
        route: "addMaintenanceType",
      },
    ],
  },

  {
    title: "g.Labs",
    icon: "BriefcaseIcon",
    permission: "labs",

    children: [
      {
        title: "g.listOfLabs",
        subPermission: "index",
        route: "ListOfLabs",
      },
      {
        title: "g.addLab",
        route: "AddLab",
        subPermission: "store",
      },
      // {
      //   title: "g.recordOfRisks",
      //   route: "RecordOfRisks",
      // },
      // {
      //   title: "g.recordOfOpportunities",
      //   route: "RecordOfOpportunities",
      // },
    ],
  },

  {
    title: "g.maintenance",
    icon: "ToolIcon",
    permission: "maintenanceTypes",
    children: [
      {
        title: "g.maintenanceTypesList",
        route: "maintenanceTypesList",
        subPermission: "index",
      },
      {
        title: "g.addMaintenanceType",
        route: "addMaintenanceType",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.categories",
    icon: "PieChartIcon",
    permission: "categories",
    children: [
      {
        title: "g.ListOfCategories",
        route: "ListOfCategories",
        subPermission: "index",
      },
      {
        title: "g.AddCategory",
        route: "AddCategory",
        subPermission: "store",
      },
    ],
  },

  {
    title: "g.correctActionTypes",
    icon: "CheckCircleIcon",
    permission: "correctActionTypes",
    children: [
      {
        title: "g.listCorrectActionType",
        route: "ListCorrectActionType",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.riskTypes",
    icon: "BarChart2Icon",
    permission: "riskTypes",
    children: [
      {
        title: "g.listOfRisks",
        route: "ListOfRisks",
        subPermission: "index",
      },
      {
        title: "g.AddRiskType",
        route: "AddRiskType",
        subPermission: "store",
      },
    ],
  },

  {
    title: "g.complaint",
    icon: "ArchiveIcon",
    permission: "complaints",
    children: [
      {
        title: "g.addComplaint",
        route: "Complaint",
        subPermission: "store",
      },
      {
        title: "g.listOfComplaint",
        route: "ListOfComplaint",
        subPermission: "index",
      },
      {
        title: "g.myComplaint",
        route: "AssignedComplaint",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.documents",
    icon: "FileTextIcon",
    permission: "paperTypes",
    children: [
      {
        title: "g.QualityDocumentList",
        route: "QualityDocumentList",
        subPermission: "index",
      },

      {
        title: "g.ExternalDocumentList",
        route: "ExternalDocumentList",
        subPermission: "index",
      },

      {
        title: "g.TestSpecificationsDocumentList",
        route: "TestSpecificationsDocumentList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.externallyProvidedProductsAndServices",
    icon: "DiscIcon",
    permission: "buyRequests",
    children: [
      {
        title: "g.servicesPurchaseRequestList",
        route: "ServicesPurchaseRequestList",
        subPermission: "index",
      },
      {
        title: "g.addServicePurchaseRequest",
        route: "AddServicePurchaseRequest",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.BuyRequestTypes",
    icon: "DiscIcon",
    permission: "buyRequestTypes",
    children: [
      {
        title: "g.ListOfBuyRequestTypes",
        route: "ListOfBuyRequestTypes",
        subPermission: "index",
      },
      {
        title: "g.AddBuyRequestType",
        route: "AddBuyRequestType",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.clients",
    icon: "UsersIcon",
    permission: "clients",

    children: [
      {
        title: "g.ClientsList",
        route: "ClientsList",
        subPermission: "index",
      },
      {
        title: "g.addClient",
        route: "AddClient",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.LiabilityAndFinancing",
    icon: "DollarSignIcon",
    permission: "archives",

    children: [
      {
        title: "g.LiabilityAndFinancingList",
        route: "LiabilityAndFinancingList",
        subPermission: "index",
      },
    ],
  },

  {
    title: "g.suppliers",
    icon: "TruckIcon",
    permission: "suppliers",
    children: [
      {
        title: "g.ListSuppliers",
        route: "ListSuppliers",
        subPermission: "index",
      },
      {
        title: "g.AddSupplier",
        route: "AddSuppliers",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.ServiceRequest",
    icon: "ClipboardIcon",
    permission: "serviceRequests",
    children: [
      {
        title: "g.ListServiceRequests",
        route: "ListServiceRequests",
        subPermission: "index",
      },
      {
        title: "g.AddServiceRequest",
        route: "AddServiceRequest",
        subPermission: "store",
      },
    ],
  },

  {
    title: "g.impartiality",
    icon: "CodeIcon",
    permission: "archives",

    children: [
      {
        title: "g.OrganizationalStructureList",
        route: "OrganizationalStructureList",
        subPermission: "index",
      },
      {
        title: "g.RelationshipBetweenTheDepartmentsList",
        route: "RelationshipBetweenTheDepartmentsList",
        subPermission: "index",
      },
      {
        title: "g.ManagementCommitmentOfImpartialityList",
        route: "ManagementCommitmentOfImpartialityList",
        subPermission: "index",
      },
      {
        title: "g.CommitteeOfSafeguardingImpartialityList",
        route: "CommitteeOfSafeguardingImpartialityList",
        subPermission: "index",
      },
      {
        title: "g.ManagementCommitmentOfIndependenceList",
        route: "ManagementCommitmentOfIndependenceList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.nonDiscriminatoryConditions",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.CommitmentOfNonDiscriminationList",
        route: "CommitmentOfNonDiscriminationList",
        subPermission: "index",
      },
      {
        title: "g.ThePolicyOfCBDCRList",
        route: "ThePolicyOfCBDCRList",
        subPermission: "index",
      },
      {
        title: "g.RiskManagementProcedureList",
        route: "RiskManagementProcedureList",
        subPermission: "index",
      },
      {
        title: "g.RiskEvaluationOfImpartialityList",
        route: "RiskEvaluationOfImpartialityList",
        subPermission: "index",
      },
      {
        title: "g.InformTheGSOAboutRejectionProductsList",
        route: "InformTheGSOAboutRejectionProductsList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.publiclyAvailableInformation",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.ThePolicyCBDList",
        route: "ThePolicyCBDList",
        subPermission: "index",
      },
      {
        title:
          "g.ApprovalByTheClientToAllowThePublicationOfSpecificInformation",
        route: "ClientsConsentToPublishList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.legalAndContractualMatters",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.SaitcoLegalEntityList",
        route: "SaitcoLegalEntityList",
        subPermission: "index",
      },
      {
        title: "g.FinalLicenseOfELELabList",
        route: "FinalLicenseOfELELabList",
        subPermission: "index",
      },
      {
        title: "g.FinalLicenseOfTEXLabList",
        route: "FinalLicenseOfTEXLabList",
        subPermission: "index",
      },
      {
        title: "g.FinalLicenseOfGPLabList",
        route: "FinalLicenseOfGPLabList",
        subPermission: "index",
      },
      {
        title: "g.PrimaryFinalLicenseOfCosmeticsLabList",
        route: "PrimaryFinalLicenseOfCosmeticsLabList",
        subPermission: "index",
      },
      {
        title: "g.PrimaryFinalLicenseOfSPLabList",
        route: "PrimaryFinalLicenseOfSPLabList",
        subPermission: "index",
      },
      {
        title: "g.LicenseOfChinaBranchList",
        route: "LicenseOfChinaBranchList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.responsibilityOfTheDifferentDepartments",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.DepartmentsResponsibilityOfIBList",
        route: "DepartmentsResponsibilityOfIBList",
        subPermission: "index",
      },
      {
        title: "g.DepartmentsResponsibilityOfTBList",
        route: "DepartmentsResponsibilityOfTBList",
        subPermission: "index",
      },
      {
        title: "g.DepartmentsResponsibilityOfCBList",
        route: "DepartmentsResponsibilityOfCBList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.scopes",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.ScopeOfAccreditationOfELELabList",
        route: "ScopeOfAccreditationOfELELabList",
        subPermission: "index",
      },
      {
        title: "g.ScopeOfAccreditationOfTEXLabList",
        route: "ScopeOfAccreditationOfTEXLabList",
        subPermission: "index",
      },
      {
        title: "g.ScopeOfAccreditationOfGPLabList",
        route: "ScopeOfAccreditationOfGPLabList",
        subPermission: "index",
      },
      {
        title: "g.ScopeOfAccreditationCertificateOfCosmeticsLabList",
        route: "ScopeOfAccreditationCertificateOfCosmeticsLabList",
        subPermission: "index",
      },
      {
        title: "g.ScopeOfAccreditationCertificateOfSPLabList",
        route: "ScopeOfAccreditationCertificateOfSPLabList",
        subPermission: "index",
      },
      {
        title: "g.ScopeOfAccreditationOfIBList",
        route: "ScopeOfAccreditationOfIBList",
        subPermission: "index",
      },

      {
        title: "g.ScopeOfAccreditationOfCBList",
        route: "ScopeOfAccreditationOfCBList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.OrganizationalStructureAndSeniorManagement",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.OrganizationalStructureOfIBList",
        route: "OrganizationalStructureOfIBList",
        subPermission: "index",
      },
      {
        title: "g.OrganizationalStructureOfLabsList",
        route: "OrganizationalStructureOfLabsList",
        subPermission: "index",
      },
      {
        title: "g.OrganizationalStructureOfCBList",
        route: "OrganizationalStructureOfCBList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.MechanismForSafeguardingImpartiality",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.RecordOfImpartialityCommitteePersonal",
        route: "RecordOfImpartialityCommitteePersonal",
        subPermission: "index",
      },
      {
        title: "g.RecordOfImpartialityCommitteeMeeting",
        route: "RecordOfImpartialityCommitteeMeeting",
        subPermission: "index",
      },
      {
        title: "g.ImpartialityCommitteeInvitation",
        route: "ImpartialityCommitteeInvitation",
        subPermission: "index",
      },
      {
        title: "g.ReportOfImpartialityCommitteeMeeting",
        route: "ReportOfImpartialityCommitteeMeeting",
        subPermission: "index",
      },
      {
        title: "g.certificationRequestAgreement",
        route: "CertificationRequestAgreement",
        subPermission: "index",
      },
      {
        title: "g.ControlOfConformityMarkingList",
        route: "ControlOfConformityMarkingList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.facilitiesAndEnvironmentalConditions",
    icon: "FileTextIcon",
    permission: "archives",
    children: [
      // {
      //   title: "g.temperatureAndHumidityList",
      //   route: "TemperatureAndHumidityList",
      //   subPermission: "index",
      // },
      // {
      //   title: "g.addAuthorizedToUseAComputerList",
      //   route: "authorizedToUseAComputerList",
      //   subPermission: "index",
      // },
      {
        title: "g.AuthorizedPersonInAssessmentBodyList",
        route: "AuthorizedPersonInAssessmentBodyList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.confidentiality",
    icon: "LockIcon",
    permission: "archives",

    children: [
      {
        title: "g.ConfidentialityAndImpartialityCommitmentList",
        route: "ConfidentialityAndImpartialityCommitmentList",
        subPermission: "index",
      },
      {
        title: "g.ReleaseConfidentialInformationList",
        route: "ReleaseConfidentialInformationList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.qualityPolicyAndObjectives",
    icon: "AwardIcon",
    permission: "archives",

    children: [
      {
        title: "g.QualityPolicyAndObjectivesList",
        route: "QualityPolicyAndObjectivesList",
        subPermission: "index",
      },
    ],
  },

  {
    title: "g.SelectionAndVerificationAndValidationOfMethods",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.AcceptanceAndRejectionCriteriaList",
        route: "AcceptanceAndRejectionCriteriaList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.handlingOfTestOrCalibrationItems",
    icon: "FileTextIcon",
    permission: "archives",
    children: [
      {
        title: "g.SampleLayoutOfReceptionList",
        route: "SampleLayoutOfReceptionList",
        subPermission: "index",
      },
      {
        title: "g.ProcedureOfSamplingList",
        route: "ProcedureOfSamplingList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.TechnicalRecords",
    icon: "FolderIcon",
    permission: "archives",

    children: [
      {
        title: "g.DealingWithTechnicalRecordsList",
        route: "DealingWithTechnicalRecordsList",
        subPermission: "index",
      },
      {
        title: "g.controlOfTechnicalRecordList",
        route: "ControlOfTechnicalRecordList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.ensuringTheValidityOfResults",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.InterLabComparaisonProtocolILCList",
        route: "InterLabComparaisonProtocolILCList",
        subPermission: "index",
      },
      {
        title: "g.VerificationProcedureList",
        route: "VerificationProcedureList",
        subPermission: "index",
      },
      {
        title: "g.InsuranceOfTestsWithControlChartsList",
        route: "InsuranceOfTestsWithControlChartsList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.reportingOfResults",
    icon: "FileTextIcon",
    permission: "archives",

    children: [
      {
        title: "g.InstructionOfUsingABLogoList",
        route: "InstructionOfUsingABLogoList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.roles",
    icon: "KeyIcon",
    permission: "roles",
    children: [
      {
        title: "g.listRoles",
        route: "ListRoles",
        subPermission: "index",
      },
      {
        title: "g.addRole",
        route: "AddRole",
        subPermission: "store",
      },
    ],
  },
];
