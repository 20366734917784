<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--  Translate dropdown -->
      <locale />
      <!-- ** Translate dropdown -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
            <span class="user-status">{{ role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          :to="{ name: 'Profile' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("g.Profile") }}</span>
        </b-dropdown-item>

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("g.Logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Locale from "../../@core/layouts/components/app-navbar/components/Locale.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      userName: null,
      role: null,
    };
  },

  created() {
    this.$http
      .get("web/users")
      .then((res) => {
        if (res.status === 200) {
          const { data } = res.data;
          store.commit("UPDATE_STATE", data?.roles[0]?.permissions);
          store.commit("UPDATE_ROLE", data?.roles[0]?.name);
          this.userName = `${data?.first_name} ${data?.last_name}`;
          this.role = data?.roles[0]?.name;
        }
      })
      .catch((err) => {
        const { response } = err;
        if (response?.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
  },

  methods: {
    logout() {
      localStorage.clear();
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Welcome`,
          icon: "CheckCircleIcon",
          variant: "success",
          text: `You have successfully logged in as. Now you can start to explore!`,
        },
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    },
  },
};
</script>
